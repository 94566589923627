import React, {MutableRefObject, useCallback, useEffect, useState} from "react"
import StoryTransitionNumber from "./story-transition-number";

type Props = {
    timeToStartRef: MutableRefObject<number>,
    children?: React.ReactNode

    fast ?: boolean
}


const startTime = 10000
const startTimeFast = 8000
const endTime = 4000

const StoryTransitions: React.FC<Props> = (props) => {

    const childSize = React.Children.count(props.children)
    const start = props.fast ? startTimeFast : startTime
    const intervalSize = (start - endTime) / childSize

    const [timeRemaining, setTimeRemaining] = useState<number>(props.timeToStartRef.current)

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(props.timeToStartRef.current)
        }, 250)
        return () => {
            clearInterval(interval)
        }
    }, [props.timeToStartRef])

    const visible = useCallback((idx: number) => {
        if (idx === 0) {
            return true
        }
        const threshold = start - (idx * intervalSize)
        return threshold >= timeRemaining

    }, [intervalSize, timeRemaining, start])

    return <div className={"flex-grow pt-2 px-2 flex flex-col justify-center items-stretch"}>
        {React.Children.map(props.children, (a, idx) => {
            if (React.isValidElement(a)) {
                return React.cloneElement(a as React.ReactElement<any>, {hidden: !visible(idx)}, null)
            } else {
                return a
            }
        })}

        <div className={"my-4 flex flex-row items-stretch justify-around space-x-2"}>
            <StoryTransitionNumber text={"3"} visible={timeRemaining < 4000} grow/>
            <StoryTransitionNumber text={"2"} visible={timeRemaining < 3000} grow/>
            <StoryTransitionNumber text={"1"} visible={timeRemaining < 2000} grow/>
        </div>
        <div className={"flex flex-row items-stretch justify-center text-center"}>
            <StoryTransitionNumber text={"GO!"} visible={timeRemaining < 1000}/>
        </div>
    </div>
}

export default StoryTransitions
